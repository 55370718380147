// @font-face {
//   font-family: "myfont";
//   src: url('../fonticon/fonts/myfont.eot');
//   src: url('../fonticon/fonts/myfont.eot?#iefix') format('eot'),
//     url('../fonticon/fonts/myfont.woff2') format('woff2'),
//     url('../fonticon/fonts/myfont.woff') format('woff'),
//     url('../fonticon/fonts/myfont.ttf') format('truetype'),
//     url('../fonticon/fonts/myfont.svg#myfont') format('svg');
//   font-weight: normal;
//   font-style: normal;
// }

// .font-icon:before {
//   display: inline-block;
//   font-family: "myfont";
//   font-style: normal;
//   font-weight: normal;
//   line-height: 1;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// .font-icon-lg {
//   font-size: 1.3333333333333333em;
//   line-height: 0.75em;
//   vertical-align: -15%;
// }
// .font-icon-2x { font-size: 2em; }
// .font-icon-3x { font-size: 3em; }
// .font-icon-4x { font-size: 4em; }
// .font-icon-5x { font-size: 5em; }
// .font-icon-fw {
//   width: 1.2857142857142858em;
//   text-align: center;
// }

// .icon-arrow-left:before { content: "\EA01" }
// .icon-arrow-right:before { content: "\EA02" }
// .icon-bag:before { content: "\EA03" }
// .icon-bell:before { content: "\EA04" }
// .icon-check:before { content: "\EA05" }
// .icon-close:before { content: "\EA06" }
// .icon-concept-icon:before { content: "\EA07" }
// .icon-delete:before { content: "\EA08" }
// .icon-down-arrow:before { content: "\EA09" }
// .icon-facebook:before { content: "\EA0A" }
// .icon-heart-full:before { content: "\EA0B" }
// .icon-heart:before { content: "\EA0C" }
// .icon-insta:before { content: "\EA0D" }
// .icon-mail:before { content: "\EA0E" }
// .icon-minus:before { content: "\EA0F" }
// .icon-phone:before { content: "\EA10" }
// .icon-pin:before { content: "\EA11" }
// .icon-plus:before { content: "\EA12" }
// .icon-search:before { content: "\EA13" }
// .icon-shopping-bag:before { content: "\EA14" }
// .icon-triangle-down:before { content: "\EA15" }
// .icon-up-arrow:before { content: "\EA16" }
// .icon-wchicon:before { content: "\EA17" }


@charset "UTF-8";

@font-face {
  font-family: "mothercare";
  src:url("../mothercareicons/mothercare.eot");
  src:url("../mothercareicons/mothercare.eot?#iefix") format("embedded-opentype"),
    url("../mothercareicons/mothercare.woff") format("woff"),
    url("../mothercareicons/mothercare.ttf") format("truetype"),
    url("../mothercareicons/mothercare.svg#mothercare") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "mothercare" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-icon:before,
.font-icon:before {
  font-family: "mothercare" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.mc-icon-arrow-down:before {
  content: "\61";
}
.mc-icon-arrow-up:before {
  content: "\62";
}
.mc-icon-arrow-down-thin:before {
  content: "\63";
}
.mc-icon-arrow-left:before {
  content: "\64";
}
.mc-icon-arrow-right:before {
  content: "\65";
}
.mc-icon-arrow-up-1:before {
  content: "\66";
}
.mc-icon-badge:before {
  content: "\67";
}
.mc-icon-carrow-left-1:before {
  content: "\68";
}
.mc-icon-baby-boy:before {
  content: "\69";
}
.mc-icon-calendar:before {
  content: "\6a";
}
.mc-icon-car:before {
  content: "\6b";
}
.mc-icon-car-small:before {
  content: "\6c";
}
.mc-icon-book:before {
  content: "\6d";
}
.mc-icon-tick-bold:before {
  content: "\6e";
}
.mc-icon-cursor-circle:before {
  content: "\6f";
}
.mc-icon-close:before {
  content: "\70";
}
.mc-icon-chat:before {
  content: "\71";
}
.mc-icon-cursor:before {
  content: "\72";
}
.mc-icon-calendar-alt:before {
  content: "\73";
}
.mc-icon-folder:before {
  content: "\74";
}
.mc-icon-box:before {
  content: "\75";
}
.mc-icon-gift-dots:before {
  content: "\76";
}
.mc-icon-gift-stripes:before {
  content: "\77";
}
.mc-icon-gift:before {
  content: "\78";
}
.mc-icon-present:before {
  content: "\79";
}
.mc-icon-baby-girl:before {
  content: "\7a";
}
.mc-icon-grid:before {
  content: "\41";
}
.mc-icon-question:before {
  content: "\42";
}
.mc-icon-house:before {
  content: "\43";
}
.mc-icon-info:before {
  content: "\44";
}
.mc-icon-link:before {
  content: "\45";
}
.mc-icon-list:before {
  content: "\46";
}
.mc-icon-chat-thin:before {
  content: "\47";
}
.mc-icon-target:before {
  content: "\48";
}
.mc-icon-lock:before {
  content: "\49";
}
.mc-icon-mail:before {
  content: "\4a";
}
.mc-icon-burger:before {
  content: "\4b";
}
.mc-icon-minus:before {
  content: "\4c";
}
.mc-icon-mother:before {
  content: "\4d";
}
.mc-icon-box-alt:before {
  content: "\4e";
}
.mc-icon-people:before {
  content: "\4f";
}
.mc-icon-phone:before {
  content: "\50";
}
.mc-icon-plane:before {
  content: "\51";
}
.mc-icon-plus:before {
  content: "\52";
}
.mc-icon-printer:before {
  content: "\53";
}
.mc-icon-quicklook:before {
  content: "\54";
}
.mc-icon-england:before {
  content: "\55";
}
.mc-icon-search:before {
  content: "\56";
}
.mc-icon-minicart:before {
  content: "\57";
}
.mc-icon-account:before {
  content: "\58";
}
.mc-icon-ruler:before {
  content: "\59";
}
.mc-icon-carrow-left:before {
  content: "\5a";
}
.mc-icon-carrow-down:before {
  content: "\30";
}
.mc-icon-chat-fill:before {
  content: "\31";
}
.mc-icon-facebook:before {
  content: "\32";
}
.mc-icon-google:before {
  content: "\33";
}
.mc-icon-instagram:before {
  content: "\34";
}
.mc-icon-linkedin:before {
  content: "\35";
}
.mc-icon-pinterest:before {
  content: "\36";
}
.mc-icon-twitter:before {
  content: "\37";
}
.mc-icon-youtube:before {
  content: "\38";
}
.mc-icon-store:before {
  content: "\39";
}
.mc-icon-location:before {
  content: "\21";
}
.mc-icon-tick:before {
  content: "\22";
}
.mc-icon-lock-open:before {
  content: "\23";
}
.mc-icon-track:before {
  content: "\24";
}
.mc-icon-gift-card:before {
  content: "\25";
}
.mc-icon-heart:before {
  content: "\26";
}
.mc-icon-mother-child:before {
  content: "\27";
}
.mc-icon-mail-box:before {
  content: "\28";
}
.mc-icon-mail-fill:before {
  content: "\29";
}
.mc-icon-phone-1:before {
  content: "\2a";
}
.mc-icon-dots:before {
  content: "\2b";
}
.mc-icon-ctick:before {
  content: "\2c";
}
.mc-icon-track-bold:before {
  content: "\2d";
}
.mc-icon-question-bold:before {
  content: "\2e";
}
.mc-icon-store-alt:before {
  content: "\2f";
}
.mc-icon-info-bold:before {
  content: "\3a";
}
.mc-icon-close-bold:before {
  content: "\3b";
}
.mc-icon-specifications:before {
  content: "\3c";
}
.mc-icon-start-half:before {
  content: "\3d";
}
.mc-icon-start:before {
  content: "\3e";
}
.mc-icon-star-fill:before {
  content: "\3f";
}
.mc-icon-users:before {
  content: "\40";
}
.mc-icon-whatsup:before {
  content: "\5b";
}
