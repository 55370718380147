// @font-face {
//   font-family: mc-regular;
//   src: url(../fonts/mothercaremedium-webfont.eot?) format("eot");
//   src: url(../fonts/mothercaremedium-webfont.eot?#iefix) format("embedded-opentype"), url(../fonts/mothercaremedium-webfont.woff2) format("woff2"), url(../fonts/mothercaremedium-webfont.woff) format("woff"), url(../fonts/mothercaremedium-webfont.ttf) format("truetype"), url(../fonts/mothercaremedium-webfont.svg#mc-regular) format("svg");
//   font-style: normal;
//   font-weight: 400
// }

// @font-face {
//   font-family: ThrowMyHandsUpintheAir;
//   src: url(../fonts/ThrowMyHandsUpintheAir.eot?) format("eot");
//   src: url(../fonts/ThrowMyHandsUpintheAir.eot?#iefix) format("embedded-opentype"), url(../fonts/ThrowMyHandsUpintheAir.woff2) format("woff2"), url(../fonts/ThrowMyHandsUpintheAir.woff) format("woff"), url(../fonts/ThrowMyHandsUpintheAir.ttf) format("truetype"), url(../fonts/ThrowMyHandsUpintheAir.svg#ThrowMyHandsUpintheAir) format("svg");
//   font-style: normal;
//   font-weight: 400
// }

@import url("https://use.typekit.net/ddy5kqp.css");

// @font-face {
//   font-family: "mc-thin";
//   // src: url("../fonts/mothercarethin-webfont.eot?") format("eot");
//   // src: url("../fonts/mothercarethin-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/mothercarethin-webfont.woff2") format("woff2"), url("../fonts/mothercarethin-webfont.woff") format("woff"), url("../fonts/mothercarethin-webfont.ttf") format("truetype"), url("../fonts/mothercarethin-webfont.svg#mc-thin") format("svg");
//   src: url("../fonts/mothercarethin-webfont.woff2") format("woff2");
//   font-style: normal;
//   font-display: auto;
//   font-weight: 300;
// }

// @font-face{
//     font-family:"futura-plus-thin";
//     font-weight: 300;
//     font-display: auto;
//     // src:url("../fonts/futura-plus-thin.eot?#iefix");
//     // src:url("../fonts/futura-plus-thin.eot?#iefix") format("eot"),url("../fonts/futura-plus-thin.woff2") format("woff2"),url("../fonts/futura-plus-thin.woff") format("woff"),url("../fonts/futura-plus-thin.ttf") format("truetype");
//     src: url("../fonts/futura-plus-thin.woff2") format("woff2");

// }

// @font-face{
//     font-family:"futura-plus";
//     font-weight: 400;
//     font-display: auto;
//     // src:url("../fonts/futura-plus.eot?#iefix");
//     // src:url("../fonts/futura-plus.eot?#iefix") format("eot"),url("../fonts/futura-plus.woff2") format("woff2"),url("../fonts/futura-plus.woff") format("woff"),url("../fonts/futura-plus.ttf") format("truetype");
//     src: url("../fonts/futura-plus.woff2") format("woff2");

// }


// Colors
$black: #000;
$black: #414141;
$grey: #9EA9AD;
$green: #67BCA8;
$red: #ca2130;
$blue: #0085c9;
$border: #d6d6d6;
$grey_icon: #C4C4C4;
$background: #F2F2F2;
$background_light: #E5E5E5;
$main: #003971;

// Breakpoint variables.
$mob: 360px;
$tab: 760px;
$desk: 1000px;
$wide: 1480px;

// Fonts
// $default_font: 'futura-plus';
// $thin_font: 'mc-thin', sans-serif;
// $second_font: 'PT Sans', sans-serif;
// $default_font: 'mc-regular', sans-serif;
// $default_font: 'proxima-soft', sans-serif;;
// proxima-soft, sans-serif;

$default_font: 'futura-pt';
$thin_font: 'mc-thin', sans-serif;
$second_font: 'PT Sans', sans-serif;

$font-size-h1: 30px;
$font-size-h2: 20px;
$font-size-h3: 18px;
$font-size-h4: 16px;
$font-size-h5: 14px;
$font-size-h6: 12px;
