@import "compass";
@import "breakpoint";
@import "variables/variables.scss";
@import "variables/icons.scss";
.cart-block .cart-wrapper {
  @include pie-clearfix;
  position: relative;
  @include breakpoint($desk) {
    padding-left: 65px;
  }
  &:before {
    @extend .font-icon;
    @extend .mc-icon-minicart;
    font-size: 26px;
    color: $main;
    display: block;
    text-align: center;
    @include breakpoint($tab) {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translatey(-50%);
      display: inline;
      @include breakpoint($desk) {
        font-size: 44px;
        bottom: 3px;
        top: unset;
        transform: none;
      }
    }
  }
  &:hover::before {
    color: #0085c9;
  }
  .bottom-cart-wrapper {
    padding-left: 0;
    @include breakpoint($tab) {
      padding-left: 35px;
      @include breakpoint($desk) {
        padding-left: 0;
      }
    }
  }
}

.cart-block-active {
  .cart-block .cart-wrapper:hover {
    color: #0085c9;
  }
}

.total-cart-items {
  background: #cce7f4;
  color: $main;
  padding: 5px 20px;
  font-size: 20px;
  line-height: 32px;
  @include breakpoint($desk) {
    width: 65%;
    float: left;
  }
}

.checkout-breadcrumb {
  width: 100%;
  ul.breadcrumb {
    li {
      margin: 0;
      text-align: left;
      background: #cce7f4;
      line-height: 11px;
      font-size: 10px;
      position: relative;
      color: #0085c9;
      text-align: center;
      margin-bottom: 10px;
      display: inline-block;
      float: left;
      height: 26px;
      padding: 0 9px 0 27px;
      width: 29%;

      @include breakpoint($tab) {
        height: 44px;
        padding: 0 0 0 28px;
      }

      &:before {
        z-index: 2;
        position: absolute;
        top: 0;
        left: 100%;
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border: 13px solid transparent;
        border-left-color: #cce7f4;

        @include breakpoint($tab) {
          border: 22px solid transparent;
          border-left-color: #cce7f4;
        }
      }
      &:after {
        z-index: 1;
        position: absolute;
        top: -6px;
        left: 100%;
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border: 19px solid transparent;
        border-left-color: #fff;
        
        @include breakpoint($tab) {
          border: 28px solid transparent;
          border-left-color: #fff;
        }
      }
      &:last-child {
        width: 13%;
        padding-right: 5px;

        @include breakpoint($tab) {
          padding-right: 0;
        }
        strong {
          font-size: 0 !important;
          &:before {
            content: "\2713";
            font-size: 20px !important;
            line-height: 26px;
          }
        }
        @include breakpoint($tab) {
          width: 25%;
          a,
          strong {
            font-size: 15px !important;
            &:before {
              display: none;
            }
          }
        }
      }
      @include breakpoint($tab) {
        margin-bottom: 0;
        width: 25%;
        @include breakpoint($desk) {
          padding: 0 0 0 40px;
          line-height: 44px;
          font-size: 16px;
        }
      }
      &:first-child {
        padding-left: 0;
      }
      a {
        color: #0085c9;
        text-decoration: none;
        position: absolute;
        top: 50%;
        font-size: 10px;
        transform: translate(-50%, -50%);
        @include breakpoint($mob) {
          font-size: 12px;
        }
        @include breakpoint($tab) {
          font-size: 15px;
          line-height: 16px;
        }
        @include breakpoint($desk) {
          position: relative;
          top: unset;
          font-size: 16px;
          transform: none;
        }
      }
      strong {
        position: absolute;
        top: 50%;
        font-size: 10px;
        transform: translate(-50%, -50%);
        @include breakpoint($mob) {
          font-size: 12px;
        }
        @include breakpoint($tab) {
          font-size: 15px;
          line-height: 16px;
        }
        @include breakpoint($desk) {
          position: relative;
          top: unset;
          transform: none;
          font-size: 16px;
        }
      }
    }
  }
}

.view-commerce-cart-form {
  .view-content {
    table,
    thead,
    tr,
    th,
    td {
      border: 0;
    }
    th {
      text-align: left;
      padding: 10px 20px;
    }
    thead {
      // border-bottom: 1px solid #d6d6d6;
      background: #eaeaea;
    }
    .views-table {
      padding-right: 10px;
      margin: 0;
      @include breakpoint($desk) {
        width: 65%;
        float: left;
      }
      tr {
        border-bottom: 1px solid #d6d6d6;
      }
      td.views-field-edit-quantity {
        padding: 30px 10px;
      }
    }
    .line-item-summary {
      @include breakpoint($desk) {
        width: 35%;
        float: right;
        padding-left: 20px;
      }
    }
    .delete-line-item {
      background: transparent;
      color: transparent;
      background-image: url(../svg/close.svg);
      background-repeat: no-repeat;
      background-position: center;
      border: 0;
      width: 22px;
      padding: 0;
    }
    .line-item-wrapper {
      .line-item-left-info {
        width: 25%;
        float: left;
        display: inline-block;
        position: relative;
        padding-right: 20px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .line-item-main-info {
        width: 75%;
        display: inline-block;
        float: left;
        text-align: left;
      }
      .line_item_title {
        font-size: 18px;
        color: $main;
        margin-bottom: 6px;
      }
      .title-wrapper {
        margin-bottom: 15px;
      }
      .line_item_size {
        margin: 10px 0;
      }
      .prices-wrapper {
        margin-bottom: 10px;
        text-align: left;
        display: block;
        float: right;
        font-size: 16px;
        .both-prices-wrapper {
          clear: both;
          float: right;
          text-align: right;
        }
        .final-price {
          font-weight: 400;
          color: $main;
          float: right;
        }
        label {
          font-size: 14px;
          margin-bottom: 0;
          color: #888;
          float: right; // @extend .thin-font;
        }
        .initial-price {
          font-size: 12px;
          color: #414141;
          text-decoration: line-through;
        }
      }
      .line-item-quantity {
        float: left;
        font-size: 14px;
        margin-bottom: 10px;
        color: #888;
        input {
          max-width: 65px;
        }
      }
      .preorder-message {
        display: block;
        clear: both;
        padding-top: 10px;
        color: #ff6501;
      }
      .total-line-item {
        font-size: 20px;
        color: $main;
        width: 100%;
        display: block;
        padding-top: 20px;
        clear: both; // text-align: right;
        .total-line-item-label {
          float: left;
          font-size: 14px;
          margin-bottom: 0;
          color: #888;
          float: left;
        }
        .total-line-item-amount {
          float: right;
          font-weight: 400;
        }
      }
    }
  }
  .form-actions-wrapper {
    @include breakpoint($desk) {
      width: 35%;
      float: right;
      padding-left: 20px;
    }
    .form-actions {
      border: 1px solid #d6d6d6;
      border-top: 0;
      padding: 20px 40px;
      background: #fbfbfb;
      .form-submit {
        padding: 12px 28px 13px;
        margin-bottom: 10px;
      }
      #edit-submit {
        padding: 13px 20px 12px;
        font-size: 14px;
        margin-right: 10px;
      }
      #edit-checkout {
        background: #00a900;
        border: 1px solid #00a900;
        &:hover {
          background: $main;
          border: 1px solid $main;
        }
      }
    }
  }
  .line-item-summary {
    text-align: left;
    margin-bottom: 0;
    .line-item-summary-wrapper {
      border: 1px solid #d6d6d6;
      border-bottom: 0;
      padding: 20px 40px;
      background: #fbfbfb;
      .line-item-summary-title {
        font-size: 18px;
        line-height: 20px;
        color: $main;
        margin: 0 0 10px;
      }
      .line-item-total {
        @include pie-clearfix;
        font-size: 22px;
        line-height: 26px;
        font-weight: 400;
        margin-bottom: 20px;
        .line-item-total-label {
          float: left;
        }
        .line-item-total-raw {
          float: right;
        }
      }
      .line-item-total-breakdown {
        @include pie-clearfix;
        font-size: 16px;
        line-height: 22px;
        font-weight: 400;
        margin-bottom: 10px;

        .line-item-total-label {
          float: left;
        }
        .line-item-total-raw {
          float: right;
        }
      }
    }
  }
}

body.cart-block-active .cart-block #cart-wrapper {
  // padding: 0;
  top: 63px;
  right: 0;
  @include breakpoint($tab) {
    top: 115px;
  }
  .cart-header {
    @include pie-clearfix;
    padding: 10px 20px;
    .cart-title {
      display: inline-block;
      padding: 0;
      float: left;
      color: $main;
      letter-spacing: .5px;
      margin: 0;
      font-weight: 400;
      line-height: 25px;
    }
    .cart-count-items {
      color: $main;
      float: right;
      font-size: 12px;
      line-height: 20px;
      position: relative;
      padding-right: 15px;
      padding-top: 4px;
      &:before {
        position: absolute;
        right: 0;
        top: 11px;
        font-size: 9px;
        @extend .font-icon;
        @extend .mc-icon-arrow-right;
        color: $main;
      }
      span {
        font-weight: 400;
      }
    }
  }
  .cart-contents {
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    padding: 0 20px;
    margin-bottom: 20px;
    overflow: scroll;
    max-height: calc(100% - 360px);
    @include breakpoint($tab) {
      max-height: 200px;
    }
    table {
      border: 0;
      td {
        border: 0;
      }
    }
    .view-content {
      table td {
        padding: 10px 0;
        &:last-child {
          padding: 10px 0;
        }
      }
    }
    .line-item-wrapper {
      .image-wrapper {
        width: 40%;
        float: left;
        display: inline-block;
        position: relative;
        padding-right: 20px;
        img {
          width: 100%;
          height: auto;
        }
      }
      .title-wrapper {
        width: 60%;
        display: inline-block;
        float: left;
        text-align: left;
        margin-top: 15px;
      }
      .line_item_title {
        font-size: 14px;
        color: $main;
        margin-bottom: 6px;
      }
      .prices-wrapper {
        margin-bottom: 10px;
        text-align: left;
        display: block;
        width: 100%;
        .both-prices-wrapper {
          float: right;
        }
        .final-price {
          font-size: 12px;
          font-weight: 400;
          color: $main;
          float: right;
        }
        label {
          font-size: 12px;
          margin-bottom: 0;
          color: #888;
          float: left;
        }
        .initial-price {
          font-size: 12px;
          color: #414141;
          text-decoration: line-through;
        }
      }
      .line-item-quantity {
        clear: both;
        font-size: 9px;
        margin-bottom: 17px;
        color: #888;
      }
      .total-line-item {
        font-size: 15px;
        font-weight: 400;
        color: $main;
        text-align: right;
      }
    }
  }
  .view-footer {
    display: none;
  }
  .cart-footer {
    .total-wrapper {
      @include pie-clearfix;
      padding: 10px 20px;
      background: #f6f6f6;

      .inital-order-total {
        color: #828282;
        margin-right: 10px;
        font-size: 15px;
        position: relative;

        &:after {
          content: "";
          width: auto;
          border-top: 1px solid #888;
          position: absolute;
          top: 50%;
          right: 5px;
          left: 5px;
          padding: 0 3px;
          padding-left: 0;
        }
      }

      .total-title {
        display: inline-block;
        float: left;
        font-size: 14px;
        font-weight: 400;
        color: $main;
        line-height: 25px;
        position: relative;
        top: 2px;
      }
      .total-amount {
        float: right;
        line-height: 25px;
        font-weight: 400;
        font-size: 20px;
        color: $main;
      }
      .total-description {
        clear: both;
        display: block;
        font-size: 11px;
        font-weight: 400;
        line-height: 14px;
        float: right;
        color: $main;
      }
    }
    .cart-view-button-wrapper {
      padding: 20px;
      .cart-view-button {
        padding: 0 40px;
        font-size: 15px;
        line-height: 44px;
        background: #05c705;
        color: #fff;
        display: block;
        &:hover {
          text-decoration: none;
        }
      }
    }
    .continue-shopping {
      display: block;
      padding: 0 20px 20px;

      @include breakpoint($tab) {
        display: none;
      }

      .continue-shopping-button {
        padding: 0 40px;
        font-size: 15px;
        line-height: 44px;
        background: transparent;
        color: #05c705;
        border: 1px solid #05c705;
        display: block;
      }
    }
  }
}

.content-wrapper.with-sidebar {
  @include pie-clearfix;
  .l-sidebar {
    display: inline-block;
    @include breakpoint($tab) {
      padding-right: 13px;
      float: left;
      width: 25%;
    }
  }
  .main-content-region {
    padding-left: 10px;
    width: 75%;
    display: inline-block;
    float: left;
  }
}

.l-sidebar {
  .user-options-block {
    border: 1px solid #d6d6d6;
    .block__title {
      margin: 0;
      color: #888;
      background: #f6f6f6;
      font-size: 16px;
      line-height: 21px;
      padding: 9px 20px;
    }
    ul.menu {
      li {
        width: 100%;
        &:first-child a {
          padding: 11px 20px;
        }
        a {
          padding: 11px 20px;
          color: #414141;
          border-top: 1px solid #d6d6d6;
          display: block;
          line-height: 17px;
          font-size: 13px;
          &.active {
            font-weight: 400;
            color: #003971;
          }
        }
      }
    }
  }
}

.main-content-region {
  .user-options-block {
    .block__title {
      display: none;
    }
    ul.menu {
      @include pie-clearfix;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      text-align: center;
      margin-top: 20px;
      @include breakpoint($tab) {
        text-align: left;
        margin-top: 0;
      }
      li {
        width: 100%;
        flex-basis: 100%;
        padding-bottom: 20px;
        @include breakpoint($tab) {
          width: 50%;
          flex-basis: 50%;
          padding-right: 10px;
        }
        &:nth-child(2n) {
          @include breakpoint($tab) {
            padding-left: 10px;
            padding-right: 0;
          }
        }
        &:first-child a {
          padding: 45px 35px;
          padding-left: 110px;
        }
        a {
          display: block;
          height: 100%;
          transition: all .3s ease;
          padding: 45px 35px;
          padding-left: 110px;
          font-size: 18px;
          line-height: 20px;
          color: #003971;
          border: 1px solid #d6d6d6;
          background-repeat: no-repeat;
          background-position: center left 25px;
          &:hover {
            text-decoration: none;
            border: 1px solid #f6f6f6;
            background-color: #f6f6f6;
          }
          &.my-details {
            background-image: url('../images/my_details.png');
          }
          &.my-preferences {
            background-image: url('../images/my_preferences.png');
          }
          &.my-history {
            background-image: url('../images/my_history.png');
          }
          &.my-addresses {
            background-image: url('../images/my_address.png');
          }
          &.my-mothercare {
            background-image: url('../images/my_mothercare.png');
            background-size: 65px;
          }
          &.my-wishlist {
            background-image: url('../images/my_wishlist.png');
          }
        }
      }
    }
  }
}

.my-orders-page-wrapper {
  // border: 1px solid #d6d6d6;
  .my-profile-section-header {
    display: none; // margin: 0;
    // color: #888;
    // background: #f6f6f6;
    // font-size: 20px;
    // line-height: 22px;
    // padding: 9px 20px;
    // border-bottom: 1px solid #d6d6d6;
    h3 {
      margin: 0;
    }
  }
  .my-profile-section-content {
    @include pie-clearfix;
  }
  .order-wrapper {
    margin-bottom: 20px;
    border: 1px solid #d6d6d6;
    .order-row-wrapper {
      padding: 20px 30px;
      color: #414141;
      background: #f6f6f6;
      @include pie-clearfix;
      .order-row-item-wrapper {
        width: 25%;
        float: left;
        .order-row-item {
          margin-bottom: 10px;
          &:last-child {
            margin: 0;
          }
        }
        label {
          display: block;
          font-size: 13px;
          margin-bottom: 3px;
          color: #003971;
          width: 100%;
        }
        .order-row-content {
          display: inline-block;
        }
        .order-total,
        .order-operations {
          text-align: right;
          @include breakpoint($tab) {
            text-align: left;
          }
          .label,
          .order-row-content {
            display: block;
          }
          label {
            margin-bottom: 5px;
          }
          .order-row-content {
            font-weight: 400;
            color: #003971;
            font-size: 18px;
          }
          .order-row-link {
            display: inline-block;
            background: #ff6501;
            color: #fff;
            cursor: pointer;
            padding: 12px 5px;
          }
        }
      }
    }
    .order-full-wrapper {
      padding: 30px;
      display: none;
      border-top: 1px solid #d6d6d6;
      @include pie-clearfix;
      &.open {
        display: block;
      }
      table {
        margin: 0;
        border: 0;
        tbody {
          border-top: 1px solid #d6d6d6;
        }
        tr {
          border-bottom: 1px solid #d6d6d6;
        }
        th,
        td {
          border: 0;
        }
      }
      .left-side {
        padding: 0 20px;
        @include breakpoint($tab) {
          width: 65%;
          float: left;
          padding-left: 0;
          padding-right: 15px;
        }
      }
      .right-side {
        padding: 0 20px;
        @include breakpoint($tab) {
          padding-left: 15px;
          padding-right: 0;
          width: 35%;
          float: right;
        }
        .field {
          margin-bottom: 7px;
          .field__label,
          label {
            margin-bottom: 4px;
          }
        }
      }
      .bottom-side {
        padding-top: 25px;
        clear: both;
        & > label {
          background: #f6f6f6;
          border-top: 1px solid #d6d6d6;
          border-bottom: 1px solid #d6d6d6;
          color: #888;
          display: block;
          font-size: 16px;
          line-height: 21px;
          padding: 9px 20px;
        }
        .field-collection-container {
          border: 0;
          .field-collection-view {
            margin: 0;
            padding: 0;
          }
        }
        .voucher-wrapper {
          .voucher-item {
            label {
              display: block;
              font-size: 13px;
              margin-bottom: 3px;
              color: #003971;
              width: 100%;
            }
          }
          &.open {
            .voucher-more-details {
              display: block;
            }
          }
          .voucher-products {
            margin-top: 15px;
          }
          .voucher-more-button {
            background: #ff6400;
            display: table;
            cursor: pointer;
            padding: 0 20px;
            line-height: 31px;
            font-size: 12px;
            color: #fff;
          }
          .voucher-summary {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
            -ms-flex-direction: row;
            flex-direction: row;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            border-bottom: 1px solid #d6d6d6;
            .voucher-item {
              width: 100%;
              flex-basis: 100%;
              padding: 15px 20px;
              @include breakpoint($desk) {
                width: 33.3333%;
                flex-basis: 33.3333%;
              }
            }
          }
          .voucher-more-details {
            display: none;
            border-bottom: 1px solid #d6d6d6;
            padding: 20px;
            table {
              text-align: left;
              thead tr {
                border-top: 2px solid #d6d6d6;
                background: #f6f6f6;
                color: #888;
                font-weight: bold;
              }
              td,
              th {
                text-align: left;
              }
            }
            tbody tr:last-child {
              border: 0;
            }
          }
        }
      }
    }
  }
}

.my-addresses-wrapper {
  border: 1px solid #d6d6d6;
  margin-top: 30px;
  @include breakpoint($tab) {
    margin-top: 0;
  }
  .my-addresses-title {
    margin: 0;
    color: #888;
    background: #f6f6f6;
    font-size: 20px;
    line-height: 22px;
    padding: 9px 20px;
    border-bottom: 1px solid #d6d6d6;
  }
  .my-addresses-inner {
    padding: 30px;
    @include pie-clearfix;
    .form-radios {
      @include pie-clearfix;
      margin-bottom: 20px;
    }
    .form-type-radio {
      float: left;
      text-align: center;
      font-size: 18px;
      width: 100%;
      @include breakpoint($tab) {
        width: 33.333%;
        padding-right: 20px;
      }
      label {
        border: 1px dashed #d6d6d6;
        padding: 20px;
        font-weight: 400;
        cursor: pointer;
        &:before,
        &:after {
          display: none;
        }
        .address-title {
          display: block;
        }
        .edit-icon {
          font-weight: normal;
          display: block;
          font-size: 13px;
          opacity: 0.8;
        }
        .new-address {
          border: 1px solid $black;
          border-radius: 50%;
          display: inline-block;
          width: 22px;
          font-size: 19px;
          opacity: 1;
        }
      }
      input:checked + label {
        border: 1px dashed #003971;
        background: #003971;
        color: #fff;
        .edit-icon {
          color: #fff;
        }
        .new-address {
          border: 1px solid #fff;
        }
      }
    }
    .form-type-textarea,
    .form-type-textfield,
    .form-type-select {
      display: inline-block;
      float: left;
      @include breakpoint($tab) {
        width: 50%;
        padding-left: 10px;
      }
      &:nth-child(2n) {
        @include breakpoint($tab) {
          padding-right: 10px;
          padding-left: 0;
        }
      }
    }
    .actions-wrapper {
      clear: both;
      .save-btn {
        background: #ff6501;
        border: 1px solid #ff6501;
        width: 100%;
        margin-bottom: 20px;
        @include breakpoint($tab) {
          width: auto;
          margin-bottom: 0;
          margin-right: 20px;
        }
      }
      input[type="submit"] {
        width: 100%;
        @include breakpoint($tab) {
          width: auto;
        }
      }
    }
  }
}

.my-mothercare-page-wrapper {
  border: 1px solid #d6d6d6;
  .my-mothercare-section-header {
    margin: 0;
    color: #888;
    background: #f6f6f6;
    font-size: 20px;
    line-height: 22px;
    padding: 9px 20px;
    border-bottom: 1px solid #d6d6d6;
    h3 {
      margin: 0;
    }
  }
  .my-mothercare-section-content {
    padding: 20px;
    .card-info,
    .add-card-form {
      border-top: 1px solid #d6d6d6;
      margin-top: 20px;
      h3 {
        margin: 20px 0 10px;
        color: $main;
      }
    }
    .card-wrapper {
      border: 1px solid #d6d6d6;
      padding: 20px 25px 20px 20px;
      border-radius: 4px;
      display: inline-block;
      position: relative;
      &:after {
        background-image: url('../logo_gr.png');
        content: '';
        position: absolute;
        opacity: 0.6;
        top: 10px;
        right: 10px;
        display: block;
        width: 35px;
        height: 100px;
        z-index: -1;
        background-size: contain;
        background-repeat: no-repeat;
      }
      .card-full-name {
        font-size: 14px;
      }
      .card-number {
        color: $main;
        font-weight: 400;
        font-size: 18px;
        margin-top: 5px;
      }
    }
  }
}

.user-pass {
  .information-text {
    margin: 5px auto 20px;
    max-width: none;
  }
}

//User Account Page
.page-user.not-logged-in .l-content {
  form {
    max-width: 570px;
    margin: 20px auto;
    border: 1px solid #d6d6d6;
    padding: 22px 24px;
    position: relative;
    @include breakpoint($tab) {
      margin: 40px auto;
    }
  }
}

.page-user .l-content {
  .user-forms-wrapper .user-login-left {
    padding: 0;
    width: 100%;
    @include breakpoint($tab) {
      width: 50%;
      padding: 0 10px;
      float: left;
    }
  }
  .user-forms-wrapper .user-register-right {
    padding: 0;
    width: 100%;
    @include breakpoint($tab) {
      width: 50%;
      padding: 0 10px;
      float: right;
    }
  }
  .user-forms-wrapper .user-register-right-wrapper {
    margin: 20px auto;
    @include breakpoint($tab) {
      margin: 40px auto 20px;
    }
  }
}

form.user-login .b-signin-text {
  right: auto;
  text-align: left;
  max-width: 250px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  position: relative;
  left: 0;
  @include breakpoint($tab) {
    position: absolute;
    color: #fff;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
  }
}

.user-register-with-card .b-register-text,
.user-register-form .register-banner-container .b-register-text {
  text-align: left;
  max-width: 270px;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  position: relative;
  left: 0;
  @include breakpoint($tab) {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
    color: #fff;
  }
}

.password-strength {
  width: 100%;
  @include breakpoint($tab) {
    float: right;
    margin-top: 1.2em;
    width: 17em;
  }
}

.user-register-form .selector {
  width: 100% !important;
}

.user-register-form .form-actions input[type="submit"],
.user-login .form-actions input[type="submit"],
.user-pass .form-actions input[type="submit"] {
  padding: 0;
  width: 100%;
  @include breakpoint($tab) {
    padding: 0 40px;
    width: auto;
  }
}

.content-wrapper.with-sidebar .main-content-region {
  display: inline-block;
  float: left;
  width: 100%;
  padding: 0;
  @include breakpoint($tab) {
    padding-left: 10px;
    width: 75%;
  }
}

.my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper {
  float: left;
  width: 100%;
  margin-top: 10px;
  @include breakpoint($tab) {
    margin-top: 20px;
    width: 25%;
    @include breakpoint($desk) {
      width: 25%;
      margin-top: 0;
    }
  }
  &:last-child {
    width: 100%;
    float: left;
    @include breakpoint($tab) {
      width: 100%;
      @include breakpoint($desk) {
        width: 25%;
        margin-top: 15px;
      }
    }
  }
}

.my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total .order-row-link,
.my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations .order-row-link {
  padding: 12px 3px;
}

.my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total,
.my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations {
  text-align: left;
  @include breakpoint($tab) {
    text-align: right;
    @include breakpoint($desk) {
      text-align: left;
    }
  }
}

.my-orders-page-wrapper .order-wrapper .order-full-wrapper .right-side {
  width: 100%;
  margin-top: 20px;
  @include breakpoint($tab) {
    margin-top: 0;
    padding-left: 15px;
    width: 35%;
  }
}

.my-orders-page-wrapper .order-wrapper .order-full-wrapper {
  padding: 30px 0;
  @include breakpoint($tab) {
    padding: 30px;
  }
}

.my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations .order-row-link {
  margin-top: 20px;
  float: left;
  max-width: 200px;
  width: 100%;
  text-align: center;
  @include breakpoint($tab) {
    margin-top: 0;
  }
}

.policies-accept-text {
  margin-bottom: 20px;
}

.my-account-edit {
  max-width: 570px;
  .field-name-field-mobile-prefix {
    width: 30%;
    float: left;
    padding-right: 20px;
  }
  .field-name-field-phone {
    width: 70%;
    float: left;
  }
  .field-name-field-gdpr-profiling-sw {
    margin-top: 10px;
  }
  .field-name-field-block-of-flats {
    clear: both;
  }
  .field-name-field-gdpr-profiling-sw,
  .group-contact-methods {
    clear: both;
  }
  .group-contact-methods {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    h3 {
      display: block;
      width: 100%;
    }
    .accept-all-methods-check {
      position: relative;
      padding-left: 20px;
      margin-bottom: 20px;
      font-size: 15px;
      line-height: 15px;
      // font-family: 'futura-plus-thin', sans-serif;
      font-family: $default_font;
      font-weight: 300;
      cursor: pointer;
      -webkit-font-smoothing: subpixel-antialiased;
      &:before {
        width: 12px;
        height: 12px;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        display: block;
        content: "";
        border: 1px solid #414141;
        position: absolute;
        top: 2px;
        border-radius: 2px;
        left: 0;
      }
      &.checked:after {
        width: 8px;
        height: 8px;
        border-radius: 2px;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        display: block;
        content: "";
        background: #003971;
        position: absolute;
        top: 4px;
        left: 2px;
        border: 1px solid #414141;
      }
    }
    .field-type-list-boolean {
      width: 100%;
      flex-basis: 100%;
      @include breakpoint($tab) {
        width: 50%;
        flex-basis: 50%;
      }
      @include breakpoint($desk) {
        width: 25%;
        flex-basis: 25%;
      }
    }
  }
}