@import url("https://use.typekit.net/ddy5kqp.css");
@font-face {
  font-family: "mothercare";
  src: url("../mothercareicons/mothercare.eot");
  src: url("../mothercareicons/mothercare.eot?#iefix") format("embedded-opentype"), url("../mothercareicons/mothercare.woff") format("woff"), url("../mothercareicons/mothercare.ttf") format("truetype"), url("../mothercareicons/mothercare.svg#mothercare") format("svg");
  font-weight: normal;
  font-style: normal; }

[data-icon]:before {
  font-family: "mothercare" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.font-icon:before, .cart-block .cart-wrapper:before, body.cart-block-active .cart-block #cart-wrapper .cart-header .cart-count-items:before, .font-icon:before, .cart-block .cart-wrapper:before, body.cart-block-active .cart-block #cart-wrapper .cart-header .cart-count-items:before {
  font-family: "mothercare" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.mc-icon-arrow-down:before {
  content: "\61"; }

.mc-icon-arrow-up:before {
  content: "\62"; }

.mc-icon-arrow-down-thin:before {
  content: "\63"; }

.mc-icon-arrow-left:before {
  content: "\64"; }

.mc-icon-arrow-right:before, body.cart-block-active .cart-block #cart-wrapper .cart-header .cart-count-items:before {
  content: "\65"; }

.mc-icon-arrow-up-1:before {
  content: "\66"; }

.mc-icon-badge:before {
  content: "\67"; }

.mc-icon-carrow-left-1:before {
  content: "\68"; }

.mc-icon-baby-boy:before {
  content: "\69"; }

.mc-icon-calendar:before {
  content: "\6a"; }

.mc-icon-car:before {
  content: "\6b"; }

.mc-icon-car-small:before {
  content: "\6c"; }

.mc-icon-book:before {
  content: "\6d"; }

.mc-icon-tick-bold:before {
  content: "\6e"; }

.mc-icon-cursor-circle:before {
  content: "\6f"; }

.mc-icon-close:before {
  content: "\70"; }

.mc-icon-chat:before {
  content: "\71"; }

.mc-icon-cursor:before {
  content: "\72"; }

.mc-icon-calendar-alt:before {
  content: "\73"; }

.mc-icon-folder:before {
  content: "\74"; }

.mc-icon-box:before {
  content: "\75"; }

.mc-icon-gift-dots:before {
  content: "\76"; }

.mc-icon-gift-stripes:before {
  content: "\77"; }

.mc-icon-gift:before {
  content: "\78"; }

.mc-icon-present:before {
  content: "\79"; }

.mc-icon-baby-girl:before {
  content: "\7a"; }

.mc-icon-grid:before {
  content: "\41"; }

.mc-icon-question:before {
  content: "\42"; }

.mc-icon-house:before {
  content: "\43"; }

.mc-icon-info:before {
  content: "\44"; }

.mc-icon-link:before {
  content: "\45"; }

.mc-icon-list:before {
  content: "\46"; }

.mc-icon-chat-thin:before {
  content: "\47"; }

.mc-icon-target:before {
  content: "\48"; }

.mc-icon-lock:before {
  content: "\49"; }

.mc-icon-mail:before {
  content: "\4a"; }

.mc-icon-burger:before {
  content: "\4b"; }

.mc-icon-minus:before {
  content: "\4c"; }

.mc-icon-mother:before {
  content: "\4d"; }

.mc-icon-box-alt:before {
  content: "\4e"; }

.mc-icon-people:before {
  content: "\4f"; }

.mc-icon-phone:before {
  content: "\50"; }

.mc-icon-plane:before {
  content: "\51"; }

.mc-icon-plus:before {
  content: "\52"; }

.mc-icon-printer:before {
  content: "\53"; }

.mc-icon-quicklook:before {
  content: "\54"; }

.mc-icon-england:before {
  content: "\55"; }

.mc-icon-search:before {
  content: "\56"; }

.mc-icon-minicart:before, .cart-block .cart-wrapper:before {
  content: "\57"; }

.mc-icon-account:before {
  content: "\58"; }

.mc-icon-ruler:before {
  content: "\59"; }

.mc-icon-carrow-left:before {
  content: "\5a"; }

.mc-icon-carrow-down:before {
  content: "\30"; }

.mc-icon-chat-fill:before {
  content: "\31"; }

.mc-icon-facebook:before {
  content: "\32"; }

.mc-icon-google:before {
  content: "\33"; }

.mc-icon-instagram:before {
  content: "\34"; }

.mc-icon-linkedin:before {
  content: "\35"; }

.mc-icon-pinterest:before {
  content: "\36"; }

.mc-icon-twitter:before {
  content: "\37"; }

.mc-icon-youtube:before {
  content: "\38"; }

.mc-icon-store:before {
  content: "\39"; }

.mc-icon-location:before {
  content: "\21"; }

.mc-icon-tick:before {
  content: "\22"; }

.mc-icon-lock-open:before {
  content: "\23"; }

.mc-icon-track:before {
  content: "\24"; }

.mc-icon-gift-card:before {
  content: "\25"; }

.mc-icon-heart:before {
  content: "\26"; }

.mc-icon-mother-child:before {
  content: "\27"; }

.mc-icon-mail-box:before {
  content: "\28"; }

.mc-icon-mail-fill:before {
  content: "\29"; }

.mc-icon-phone-1:before {
  content: "\2a"; }

.mc-icon-dots:before {
  content: "\2b"; }

.mc-icon-ctick:before {
  content: "\2c"; }

.mc-icon-track-bold:before {
  content: "\2d"; }

.mc-icon-question-bold:before {
  content: "\2e"; }

.mc-icon-store-alt:before {
  content: "\2f"; }

.mc-icon-info-bold:before {
  content: "\3a"; }

.mc-icon-close-bold:before {
  content: "\3b"; }

.mc-icon-specifications:before {
  content: "\3c"; }

.mc-icon-start-half:before {
  content: "\3d"; }

.mc-icon-start:before {
  content: "\3e"; }

.mc-icon-star-fill:before {
  content: "\3f"; }

.mc-icon-users:before {
  content: "\40"; }

.mc-icon-whatsup:before {
  content: "\5b"; }

.cart-block .cart-wrapper {
  *zoom: 1;
  position: relative; }
  .cart-block .cart-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 1000px) {
    .cart-block .cart-wrapper {
      padding-left: 65px; } }
  .cart-block .cart-wrapper:before {
    font-size: 26px;
    color: #003971;
    display: block;
    text-align: center; }
    @media (min-width: 760px) {
      .cart-block .cart-wrapper:before {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translatey(-50%);
        display: inline; } }
  @media (min-width: 760px) and (min-width: 1000px) {
    .cart-block .cart-wrapper:before {
      font-size: 44px;
      bottom: 3px;
      top: unset;
      transform: none; } }
  .cart-block .cart-wrapper:hover::before {
    color: #0085c9; }
  .cart-block .cart-wrapper .bottom-cart-wrapper {
    padding-left: 0; }
    @media (min-width: 760px) {
      .cart-block .cart-wrapper .bottom-cart-wrapper {
        padding-left: 35px; } }
  @media (min-width: 760px) and (min-width: 1000px) {
    .cart-block .cart-wrapper .bottom-cart-wrapper {
      padding-left: 0; } }

.cart-block-active .cart-block .cart-wrapper:hover {
  color: #0085c9; }

.total-cart-items {
  background: #cce7f4;
  color: #003971;
  padding: 5px 20px;
  font-size: 20px;
  line-height: 32px; }
  @media (min-width: 1000px) {
    .total-cart-items {
      width: 65%;
      float: left; } }

.checkout-breadcrumb {
  width: 100%; }
  .checkout-breadcrumb ul.breadcrumb li {
    margin: 0;
    text-align: left;
    background: #cce7f4;
    line-height: 11px;
    font-size: 10px;
    position: relative;
    color: #0085c9;
    text-align: center;
    margin-bottom: 10px;
    display: inline-block;
    float: left;
    height: 26px;
    padding: 0 9px 0 27px;
    width: 29%; }
    @media (min-width: 760px) {
      .checkout-breadcrumb ul.breadcrumb li {
        height: 44px;
        padding: 0 0 0 28px; } }
    .checkout-breadcrumb ul.breadcrumb li:before {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 100%;
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border: 13px solid transparent;
      border-left-color: #cce7f4; }
      @media (min-width: 760px) {
        .checkout-breadcrumb ul.breadcrumb li:before {
          border: 22px solid transparent;
          border-left-color: #cce7f4; } }
    .checkout-breadcrumb ul.breadcrumb li:after {
      z-index: 1;
      position: absolute;
      top: -6px;
      left: 100%;
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border: 19px solid transparent;
      border-left-color: #fff; }
      @media (min-width: 760px) {
        .checkout-breadcrumb ul.breadcrumb li:after {
          border: 28px solid transparent;
          border-left-color: #fff; } }
    .checkout-breadcrumb ul.breadcrumb li:last-child {
      width: 13%;
      padding-right: 5px; }
      @media (min-width: 760px) {
        .checkout-breadcrumb ul.breadcrumb li:last-child {
          padding-right: 0; } }
      .checkout-breadcrumb ul.breadcrumb li:last-child strong {
        font-size: 0 !important; }
        .checkout-breadcrumb ul.breadcrumb li:last-child strong:before {
          content: "\2713";
          font-size: 20px !important;
          line-height: 26px; }
      @media (min-width: 760px) {
        .checkout-breadcrumb ul.breadcrumb li:last-child {
          width: 25%; }
          .checkout-breadcrumb ul.breadcrumb li:last-child a,
          .checkout-breadcrumb ul.breadcrumb li:last-child strong {
            font-size: 15px !important; }
            .checkout-breadcrumb ul.breadcrumb li:last-child a:before,
            .checkout-breadcrumb ul.breadcrumb li:last-child strong:before {
              display: none; } }
    @media (min-width: 760px) {
      .checkout-breadcrumb ul.breadcrumb li {
        margin-bottom: 0;
        width: 25%; } }
  @media (min-width: 760px) and (min-width: 1000px) {
    .checkout-breadcrumb ul.breadcrumb li {
      padding: 0 0 0 40px;
      line-height: 44px;
      font-size: 16px; } }
    .checkout-breadcrumb ul.breadcrumb li:first-child {
      padding-left: 0; }
    .checkout-breadcrumb ul.breadcrumb li a {
      color: #0085c9;
      text-decoration: none;
      position: absolute;
      top: 50%;
      font-size: 10px;
      transform: translate(-50%, -50%); }
      @media (min-width: 360px) {
        .checkout-breadcrumb ul.breadcrumb li a {
          font-size: 12px; } }
      @media (min-width: 760px) {
        .checkout-breadcrumb ul.breadcrumb li a {
          font-size: 15px;
          line-height: 16px; } }
      @media (min-width: 1000px) {
        .checkout-breadcrumb ul.breadcrumb li a {
          position: relative;
          top: unset;
          font-size: 16px;
          transform: none; } }
    .checkout-breadcrumb ul.breadcrumb li strong {
      position: absolute;
      top: 50%;
      font-size: 10px;
      transform: translate(-50%, -50%); }
      @media (min-width: 360px) {
        .checkout-breadcrumb ul.breadcrumb li strong {
          font-size: 12px; } }
      @media (min-width: 760px) {
        .checkout-breadcrumb ul.breadcrumb li strong {
          font-size: 15px;
          line-height: 16px; } }
      @media (min-width: 1000px) {
        .checkout-breadcrumb ul.breadcrumb li strong {
          position: relative;
          top: unset;
          transform: none;
          font-size: 16px; } }

.view-commerce-cart-form .view-content table,
.view-commerce-cart-form .view-content thead,
.view-commerce-cart-form .view-content tr,
.view-commerce-cart-form .view-content th,
.view-commerce-cart-form .view-content td {
  border: 0; }

.view-commerce-cart-form .view-content th {
  text-align: left;
  padding: 10px 20px; }

.view-commerce-cart-form .view-content thead {
  background: #eaeaea; }

.view-commerce-cart-form .view-content .views-table {
  padding-right: 10px;
  margin: 0; }
  @media (min-width: 1000px) {
    .view-commerce-cart-form .view-content .views-table {
      width: 65%;
      float: left; } }
  .view-commerce-cart-form .view-content .views-table tr {
    border-bottom: 1px solid #d6d6d6; }
  .view-commerce-cart-form .view-content .views-table td.views-field-edit-quantity {
    padding: 30px 10px; }

@media (min-width: 1000px) {
  .view-commerce-cart-form .view-content .line-item-summary {
    width: 35%;
    float: right;
    padding-left: 20px; } }

.view-commerce-cart-form .view-content .delete-line-item {
  background: transparent;
  color: transparent;
  background-image: url(../svg/close.svg);
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  width: 22px;
  padding: 0; }

.view-commerce-cart-form .view-content .line-item-wrapper .line-item-left-info {
  width: 25%;
  float: left;
  display: inline-block;
  position: relative;
  padding-right: 20px; }
  .view-commerce-cart-form .view-content .line-item-wrapper .line-item-left-info img {
    width: 100%;
    height: auto; }

.view-commerce-cart-form .view-content .line-item-wrapper .line-item-main-info {
  width: 75%;
  display: inline-block;
  float: left;
  text-align: left; }

.view-commerce-cart-form .view-content .line-item-wrapper .line_item_title {
  font-size: 18px;
  color: #003971;
  margin-bottom: 6px; }

.view-commerce-cart-form .view-content .line-item-wrapper .title-wrapper {
  margin-bottom: 15px; }

.view-commerce-cart-form .view-content .line-item-wrapper .line_item_size {
  margin: 10px 0; }

.view-commerce-cart-form .view-content .line-item-wrapper .prices-wrapper {
  margin-bottom: 10px;
  text-align: left;
  display: block;
  float: right;
  font-size: 16px; }
  .view-commerce-cart-form .view-content .line-item-wrapper .prices-wrapper .both-prices-wrapper {
    clear: both;
    float: right;
    text-align: right; }
  .view-commerce-cart-form .view-content .line-item-wrapper .prices-wrapper .final-price {
    font-weight: 400;
    color: #003971;
    float: right; }
  .view-commerce-cart-form .view-content .line-item-wrapper .prices-wrapper label {
    font-size: 14px;
    margin-bottom: 0;
    color: #888;
    float: right; }
  .view-commerce-cart-form .view-content .line-item-wrapper .prices-wrapper .initial-price {
    font-size: 12px;
    color: #414141;
    text-decoration: line-through; }

.view-commerce-cart-form .view-content .line-item-wrapper .line-item-quantity {
  float: left;
  font-size: 14px;
  margin-bottom: 10px;
  color: #888; }
  .view-commerce-cart-form .view-content .line-item-wrapper .line-item-quantity input {
    max-width: 65px; }

.view-commerce-cart-form .view-content .line-item-wrapper .preorder-message {
  display: block;
  clear: both;
  padding-top: 10px;
  color: #ff6501; }

.view-commerce-cart-form .view-content .line-item-wrapper .total-line-item {
  font-size: 20px;
  color: #003971;
  width: 100%;
  display: block;
  padding-top: 20px;
  clear: both; }
  .view-commerce-cart-form .view-content .line-item-wrapper .total-line-item .total-line-item-label {
    float: left;
    font-size: 14px;
    margin-bottom: 0;
    color: #888;
    float: left; }
  .view-commerce-cart-form .view-content .line-item-wrapper .total-line-item .total-line-item-amount {
    float: right;
    font-weight: 400; }

@media (min-width: 1000px) {
  .view-commerce-cart-form .form-actions-wrapper {
    width: 35%;
    float: right;
    padding-left: 20px; } }

.view-commerce-cart-form .form-actions-wrapper .form-actions {
  border: 1px solid #d6d6d6;
  border-top: 0;
  padding: 20px 40px;
  background: #fbfbfb; }
  .view-commerce-cart-form .form-actions-wrapper .form-actions .form-submit {
    padding: 12px 28px 13px;
    margin-bottom: 10px; }
  .view-commerce-cart-form .form-actions-wrapper .form-actions #edit-submit {
    padding: 13px 20px 12px;
    font-size: 14px;
    margin-right: 10px; }
  .view-commerce-cart-form .form-actions-wrapper .form-actions #edit-checkout {
    background: #00a900;
    border: 1px solid #00a900; }
    .view-commerce-cart-form .form-actions-wrapper .form-actions #edit-checkout:hover {
      background: #003971;
      border: 1px solid #003971; }

.view-commerce-cart-form .line-item-summary {
  text-align: left;
  margin-bottom: 0; }
  .view-commerce-cart-form .line-item-summary .line-item-summary-wrapper {
    border: 1px solid #d6d6d6;
    border-bottom: 0;
    padding: 20px 40px;
    background: #fbfbfb; }
    .view-commerce-cart-form .line-item-summary .line-item-summary-wrapper .line-item-summary-title {
      font-size: 18px;
      line-height: 20px;
      color: #003971;
      margin: 0 0 10px; }
    .view-commerce-cart-form .line-item-summary .line-item-summary-wrapper .line-item-total {
      *zoom: 1;
      font-size: 22px;
      line-height: 26px;
      font-weight: 400;
      margin-bottom: 20px; }
      .view-commerce-cart-form .line-item-summary .line-item-summary-wrapper .line-item-total:after {
        content: "";
        display: table;
        clear: both; }
      .view-commerce-cart-form .line-item-summary .line-item-summary-wrapper .line-item-total .line-item-total-label {
        float: left; }
      .view-commerce-cart-form .line-item-summary .line-item-summary-wrapper .line-item-total .line-item-total-raw {
        float: right; }
    .view-commerce-cart-form .line-item-summary .line-item-summary-wrapper .line-item-total-breakdown {
      *zoom: 1;
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      margin-bottom: 10px; }
      .view-commerce-cart-form .line-item-summary .line-item-summary-wrapper .line-item-total-breakdown:after {
        content: "";
        display: table;
        clear: both; }
      .view-commerce-cart-form .line-item-summary .line-item-summary-wrapper .line-item-total-breakdown .line-item-total-label {
        float: left; }
      .view-commerce-cart-form .line-item-summary .line-item-summary-wrapper .line-item-total-breakdown .line-item-total-raw {
        float: right; }

body.cart-block-active .cart-block #cart-wrapper {
  top: 63px;
  right: 0; }
  @media (min-width: 760px) {
    body.cart-block-active .cart-block #cart-wrapper {
      top: 115px; } }
  body.cart-block-active .cart-block #cart-wrapper .cart-header {
    *zoom: 1;
    padding: 10px 20px; }
    body.cart-block-active .cart-block #cart-wrapper .cart-header:after {
      content: "";
      display: table;
      clear: both; }
    body.cart-block-active .cart-block #cart-wrapper .cart-header .cart-title {
      display: inline-block;
      padding: 0;
      float: left;
      color: #003971;
      letter-spacing: .5px;
      margin: 0;
      font-weight: 400;
      line-height: 25px; }
    body.cart-block-active .cart-block #cart-wrapper .cart-header .cart-count-items {
      color: #003971;
      float: right;
      font-size: 12px;
      line-height: 20px;
      position: relative;
      padding-right: 15px;
      padding-top: 4px; }
      body.cart-block-active .cart-block #cart-wrapper .cart-header .cart-count-items:before {
        position: absolute;
        right: 0;
        top: 11px;
        font-size: 9px;
        color: #003971; }
      body.cart-block-active .cart-block #cart-wrapper .cart-header .cart-count-items span {
        font-weight: 400; }
  body.cart-block-active .cart-block #cart-wrapper .cart-contents {
    border-top: 1px solid #d6d6d6;
    border-bottom: 1px solid #d6d6d6;
    padding: 0 20px;
    margin-bottom: 20px;
    overflow: scroll;
    max-height: calc(100% - 360px); }
    @media (min-width: 760px) {
      body.cart-block-active .cart-block #cart-wrapper .cart-contents {
        max-height: 200px; } }
    body.cart-block-active .cart-block #cart-wrapper .cart-contents table {
      border: 0; }
      body.cart-block-active .cart-block #cart-wrapper .cart-contents table td {
        border: 0; }
    body.cart-block-active .cart-block #cart-wrapper .cart-contents .view-content table td {
      padding: 10px 0; }
      body.cart-block-active .cart-block #cart-wrapper .cart-contents .view-content table td:last-child {
        padding: 10px 0; }
    body.cart-block-active .cart-block #cart-wrapper .cart-contents .line-item-wrapper .image-wrapper {
      width: 40%;
      float: left;
      display: inline-block;
      position: relative;
      padding-right: 20px; }
      body.cart-block-active .cart-block #cart-wrapper .cart-contents .line-item-wrapper .image-wrapper img {
        width: 100%;
        height: auto; }
    body.cart-block-active .cart-block #cart-wrapper .cart-contents .line-item-wrapper .title-wrapper {
      width: 60%;
      display: inline-block;
      float: left;
      text-align: left;
      margin-top: 15px; }
    body.cart-block-active .cart-block #cart-wrapper .cart-contents .line-item-wrapper .line_item_title {
      font-size: 14px;
      color: #003971;
      margin-bottom: 6px; }
    body.cart-block-active .cart-block #cart-wrapper .cart-contents .line-item-wrapper .prices-wrapper {
      margin-bottom: 10px;
      text-align: left;
      display: block;
      width: 100%; }
      body.cart-block-active .cart-block #cart-wrapper .cart-contents .line-item-wrapper .prices-wrapper .both-prices-wrapper {
        float: right; }
      body.cart-block-active .cart-block #cart-wrapper .cart-contents .line-item-wrapper .prices-wrapper .final-price {
        font-size: 12px;
        font-weight: 400;
        color: #003971;
        float: right; }
      body.cart-block-active .cart-block #cart-wrapper .cart-contents .line-item-wrapper .prices-wrapper label {
        font-size: 12px;
        margin-bottom: 0;
        color: #888;
        float: left; }
      body.cart-block-active .cart-block #cart-wrapper .cart-contents .line-item-wrapper .prices-wrapper .initial-price {
        font-size: 12px;
        color: #414141;
        text-decoration: line-through; }
    body.cart-block-active .cart-block #cart-wrapper .cart-contents .line-item-wrapper .line-item-quantity {
      clear: both;
      font-size: 9px;
      margin-bottom: 17px;
      color: #888; }
    body.cart-block-active .cart-block #cart-wrapper .cart-contents .line-item-wrapper .total-line-item {
      font-size: 15px;
      font-weight: 400;
      color: #003971;
      text-align: right; }
  body.cart-block-active .cart-block #cart-wrapper .view-footer {
    display: none; }
  body.cart-block-active .cart-block #cart-wrapper .cart-footer .total-wrapper {
    *zoom: 1;
    padding: 10px 20px;
    background: #f6f6f6; }
    body.cart-block-active .cart-block #cart-wrapper .cart-footer .total-wrapper:after {
      content: "";
      display: table;
      clear: both; }
    body.cart-block-active .cart-block #cart-wrapper .cart-footer .total-wrapper .inital-order-total {
      color: #828282;
      margin-right: 10px;
      font-size: 15px;
      position: relative; }
      body.cart-block-active .cart-block #cart-wrapper .cart-footer .total-wrapper .inital-order-total:after {
        content: "";
        width: auto;
        border-top: 1px solid #888;
        position: absolute;
        top: 50%;
        right: 5px;
        left: 5px;
        padding: 0 3px;
        padding-left: 0; }
    body.cart-block-active .cart-block #cart-wrapper .cart-footer .total-wrapper .total-title {
      display: inline-block;
      float: left;
      font-size: 14px;
      font-weight: 400;
      color: #003971;
      line-height: 25px;
      position: relative;
      top: 2px; }
    body.cart-block-active .cart-block #cart-wrapper .cart-footer .total-wrapper .total-amount {
      float: right;
      line-height: 25px;
      font-weight: 400;
      font-size: 20px;
      color: #003971; }
    body.cart-block-active .cart-block #cart-wrapper .cart-footer .total-wrapper .total-description {
      clear: both;
      display: block;
      font-size: 11px;
      font-weight: 400;
      line-height: 14px;
      float: right;
      color: #003971; }
  body.cart-block-active .cart-block #cart-wrapper .cart-footer .cart-view-button-wrapper {
    padding: 20px; }
    body.cart-block-active .cart-block #cart-wrapper .cart-footer .cart-view-button-wrapper .cart-view-button {
      padding: 0 40px;
      font-size: 15px;
      line-height: 44px;
      background: #05c705;
      color: #fff;
      display: block; }
      body.cart-block-active .cart-block #cart-wrapper .cart-footer .cart-view-button-wrapper .cart-view-button:hover {
        text-decoration: none; }
  body.cart-block-active .cart-block #cart-wrapper .cart-footer .continue-shopping {
    display: block;
    padding: 0 20px 20px; }
    @media (min-width: 760px) {
      body.cart-block-active .cart-block #cart-wrapper .cart-footer .continue-shopping {
        display: none; } }
    body.cart-block-active .cart-block #cart-wrapper .cart-footer .continue-shopping .continue-shopping-button {
      padding: 0 40px;
      font-size: 15px;
      line-height: 44px;
      background: transparent;
      color: #05c705;
      border: 1px solid #05c705;
      display: block; }

.content-wrapper.with-sidebar {
  *zoom: 1; }
  .content-wrapper.with-sidebar:after {
    content: "";
    display: table;
    clear: both; }
  .content-wrapper.with-sidebar .l-sidebar {
    display: inline-block; }
    @media (min-width: 760px) {
      .content-wrapper.with-sidebar .l-sidebar {
        padding-right: 13px;
        float: left;
        width: 25%; } }
  .content-wrapper.with-sidebar .main-content-region {
    padding-left: 10px;
    width: 75%;
    display: inline-block;
    float: left; }

.l-sidebar .user-options-block {
  border: 1px solid #d6d6d6; }
  .l-sidebar .user-options-block .block__title {
    margin: 0;
    color: #888;
    background: #f6f6f6;
    font-size: 16px;
    line-height: 21px;
    padding: 9px 20px; }
  .l-sidebar .user-options-block ul.menu li {
    width: 100%; }
    .l-sidebar .user-options-block ul.menu li:first-child a {
      padding: 11px 20px; }
    .l-sidebar .user-options-block ul.menu li a {
      padding: 11px 20px;
      color: #414141;
      border-top: 1px solid #d6d6d6;
      display: block;
      line-height: 17px;
      font-size: 13px; }
      .l-sidebar .user-options-block ul.menu li a.active {
        font-weight: 400;
        color: #003971; }

.main-content-region .user-options-block .block__title {
  display: none; }

.main-content-region .user-options-block ul.menu {
  *zoom: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  text-align: center;
  margin-top: 20px; }
  .main-content-region .user-options-block ul.menu:after {
    content: "";
    display: table;
    clear: both; }
  @media (min-width: 760px) {
    .main-content-region .user-options-block ul.menu {
      text-align: left;
      margin-top: 0; } }
  .main-content-region .user-options-block ul.menu li {
    width: 100%;
    flex-basis: 100%;
    padding-bottom: 20px; }
    @media (min-width: 760px) {
      .main-content-region .user-options-block ul.menu li {
        width: 50%;
        flex-basis: 50%;
        padding-right: 10px; } }
    @media (min-width: 760px) {
      .main-content-region .user-options-block ul.menu li:nth-child(2n) {
        padding-left: 10px;
        padding-right: 0; } }
    .main-content-region .user-options-block ul.menu li:first-child a {
      padding: 45px 35px;
      padding-left: 110px; }
    .main-content-region .user-options-block ul.menu li a {
      display: block;
      height: 100%;
      transition: all .3s ease;
      padding: 45px 35px;
      padding-left: 110px;
      font-size: 18px;
      line-height: 20px;
      color: #003971;
      border: 1px solid #d6d6d6;
      background-repeat: no-repeat;
      background-position: center left 25px; }
      .main-content-region .user-options-block ul.menu li a:hover {
        text-decoration: none;
        border: 1px solid #f6f6f6;
        background-color: #f6f6f6; }
      .main-content-region .user-options-block ul.menu li a.my-details {
        background-image: url("../images/my_details.png"); }
      .main-content-region .user-options-block ul.menu li a.my-preferences {
        background-image: url("../images/my_preferences.png"); }
      .main-content-region .user-options-block ul.menu li a.my-history {
        background-image: url("../images/my_history.png"); }
      .main-content-region .user-options-block ul.menu li a.my-addresses {
        background-image: url("../images/my_address.png"); }
      .main-content-region .user-options-block ul.menu li a.my-mothercare {
        background-image: url("../images/my_mothercare.png");
        background-size: 65px; }
      .main-content-region .user-options-block ul.menu li a.my-wishlist {
        background-image: url("../images/my_wishlist.png"); }

.my-orders-page-wrapper .my-profile-section-header {
  display: none; }
  .my-orders-page-wrapper .my-profile-section-header h3 {
    margin: 0; }

.my-orders-page-wrapper .my-profile-section-content {
  *zoom: 1; }
  .my-orders-page-wrapper .my-profile-section-content:after {
    content: "";
    display: table;
    clear: both; }

.my-orders-page-wrapper .order-wrapper {
  margin-bottom: 20px;
  border: 1px solid #d6d6d6; }
  .my-orders-page-wrapper .order-wrapper .order-row-wrapper {
    padding: 20px 30px;
    color: #414141;
    background: #f6f6f6;
    *zoom: 1; }
    .my-orders-page-wrapper .order-wrapper .order-row-wrapper:after {
      content: "";
      display: table;
      clear: both; }
    .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper {
      width: 25%;
      float: left; }
      .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-row-item {
        margin-bottom: 10px; }
        .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-row-item:last-child {
          margin: 0; }
      .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper label {
        display: block;
        font-size: 13px;
        margin-bottom: 3px;
        color: #003971;
        width: 100%; }
      .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-row-content {
        display: inline-block; }
      .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total,
      .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations {
        text-align: right; }
        @media (min-width: 760px) {
          .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total,
          .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations {
            text-align: left; } }
        .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total .label,
        .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total .order-row-content,
        .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations .label,
        .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations .order-row-content {
          display: block; }
        .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total label,
        .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations label {
          margin-bottom: 5px; }
        .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total .order-row-content,
        .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations .order-row-content {
          font-weight: 400;
          color: #003971;
          font-size: 18px; }
        .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total .order-row-link,
        .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations .order-row-link {
          display: inline-block;
          background: #ff6501;
          color: #fff;
          cursor: pointer;
          padding: 12px 5px; }
  .my-orders-page-wrapper .order-wrapper .order-full-wrapper {
    padding: 30px;
    display: none;
    border-top: 1px solid #d6d6d6;
    *zoom: 1; }
    .my-orders-page-wrapper .order-wrapper .order-full-wrapper:after {
      content: "";
      display: table;
      clear: both; }
    .my-orders-page-wrapper .order-wrapper .order-full-wrapper.open {
      display: block; }
    .my-orders-page-wrapper .order-wrapper .order-full-wrapper table {
      margin: 0;
      border: 0; }
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper table tbody {
        border-top: 1px solid #d6d6d6; }
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper table tr {
        border-bottom: 1px solid #d6d6d6; }
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper table th,
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper table td {
        border: 0; }
    .my-orders-page-wrapper .order-wrapper .order-full-wrapper .left-side {
      padding: 0 20px; }
      @media (min-width: 760px) {
        .my-orders-page-wrapper .order-wrapper .order-full-wrapper .left-side {
          width: 65%;
          float: left;
          padding-left: 0;
          padding-right: 15px; } }
    .my-orders-page-wrapper .order-wrapper .order-full-wrapper .right-side {
      padding: 0 20px; }
      @media (min-width: 760px) {
        .my-orders-page-wrapper .order-wrapper .order-full-wrapper .right-side {
          padding-left: 15px;
          padding-right: 0;
          width: 35%;
          float: right; } }
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper .right-side .field {
        margin-bottom: 7px; }
        .my-orders-page-wrapper .order-wrapper .order-full-wrapper .right-side .field .field__label,
        .my-orders-page-wrapper .order-wrapper .order-full-wrapper .right-side .field label {
          margin-bottom: 4px; }
    .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side {
      padding-top: 25px;
      clear: both; }
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side > label {
        background: #f6f6f6;
        border-top: 1px solid #d6d6d6;
        border-bottom: 1px solid #d6d6d6;
        color: #888;
        display: block;
        font-size: 16px;
        line-height: 21px;
        padding: 9px 20px; }
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .field-collection-container {
        border: 0; }
        .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .field-collection-container .field-collection-view {
          margin: 0;
          padding: 0; }
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper .voucher-item label {
        display: block;
        font-size: 13px;
        margin-bottom: 3px;
        color: #003971;
        width: 100%; }
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper.open .voucher-more-details {
        display: block; }
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper .voucher-products {
        margin-top: 15px; }
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper .voucher-more-button {
        background: #ff6400;
        display: table;
        cursor: pointer;
        padding: 0 20px;
        line-height: 31px;
        font-size: 12px;
        color: #fff; }
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper .voucher-summary {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        border-bottom: 1px solid #d6d6d6; }
        .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper .voucher-summary .voucher-item {
          width: 100%;
          flex-basis: 100%;
          padding: 15px 20px; }
          @media (min-width: 1000px) {
            .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper .voucher-summary .voucher-item {
              width: 33.3333%;
              flex-basis: 33.3333%; } }
      .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper .voucher-more-details {
        display: none;
        border-bottom: 1px solid #d6d6d6;
        padding: 20px; }
        .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper .voucher-more-details table {
          text-align: left; }
          .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper .voucher-more-details table thead tr {
            border-top: 2px solid #d6d6d6;
            background: #f6f6f6;
            color: #888;
            font-weight: bold; }
          .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper .voucher-more-details table td,
          .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper .voucher-more-details table th {
            text-align: left; }
        .my-orders-page-wrapper .order-wrapper .order-full-wrapper .bottom-side .voucher-wrapper .voucher-more-details tbody tr:last-child {
          border: 0; }

.my-addresses-wrapper {
  border: 1px solid #d6d6d6;
  margin-top: 30px; }
  @media (min-width: 760px) {
    .my-addresses-wrapper {
      margin-top: 0; } }
  .my-addresses-wrapper .my-addresses-title {
    margin: 0;
    color: #888;
    background: #f6f6f6;
    font-size: 20px;
    line-height: 22px;
    padding: 9px 20px;
    border-bottom: 1px solid #d6d6d6; }
  .my-addresses-wrapper .my-addresses-inner {
    padding: 30px;
    *zoom: 1; }
    .my-addresses-wrapper .my-addresses-inner:after {
      content: "";
      display: table;
      clear: both; }
    .my-addresses-wrapper .my-addresses-inner .form-radios {
      *zoom: 1;
      margin-bottom: 20px; }
      .my-addresses-wrapper .my-addresses-inner .form-radios:after {
        content: "";
        display: table;
        clear: both; }
    .my-addresses-wrapper .my-addresses-inner .form-type-radio {
      float: left;
      text-align: center;
      font-size: 18px;
      width: 100%; }
      @media (min-width: 760px) {
        .my-addresses-wrapper .my-addresses-inner .form-type-radio {
          width: 33.333%;
          padding-right: 20px; } }
      .my-addresses-wrapper .my-addresses-inner .form-type-radio label {
        border: 1px dashed #d6d6d6;
        padding: 20px;
        font-weight: 400;
        cursor: pointer; }
        .my-addresses-wrapper .my-addresses-inner .form-type-radio label:before, .my-addresses-wrapper .my-addresses-inner .form-type-radio label:after {
          display: none; }
        .my-addresses-wrapper .my-addresses-inner .form-type-radio label .address-title {
          display: block; }
        .my-addresses-wrapper .my-addresses-inner .form-type-radio label .edit-icon {
          font-weight: normal;
          display: block;
          font-size: 13px;
          opacity: 0.8; }
        .my-addresses-wrapper .my-addresses-inner .form-type-radio label .new-address {
          border: 1px solid #414141;
          border-radius: 50%;
          display: inline-block;
          width: 22px;
          font-size: 19px;
          opacity: 1; }
      .my-addresses-wrapper .my-addresses-inner .form-type-radio input:checked + label {
        border: 1px dashed #003971;
        background: #003971;
        color: #fff; }
        .my-addresses-wrapper .my-addresses-inner .form-type-radio input:checked + label .edit-icon {
          color: #fff; }
        .my-addresses-wrapper .my-addresses-inner .form-type-radio input:checked + label .new-address {
          border: 1px solid #fff; }
    .my-addresses-wrapper .my-addresses-inner .form-type-textarea,
    .my-addresses-wrapper .my-addresses-inner .form-type-textfield,
    .my-addresses-wrapper .my-addresses-inner .form-type-select {
      display: inline-block;
      float: left; }
      @media (min-width: 760px) {
        .my-addresses-wrapper .my-addresses-inner .form-type-textarea,
        .my-addresses-wrapper .my-addresses-inner .form-type-textfield,
        .my-addresses-wrapper .my-addresses-inner .form-type-select {
          width: 50%;
          padding-left: 10px; } }
      @media (min-width: 760px) {
        .my-addresses-wrapper .my-addresses-inner .form-type-textarea:nth-child(2n),
        .my-addresses-wrapper .my-addresses-inner .form-type-textfield:nth-child(2n),
        .my-addresses-wrapper .my-addresses-inner .form-type-select:nth-child(2n) {
          padding-right: 10px;
          padding-left: 0; } }
    .my-addresses-wrapper .my-addresses-inner .actions-wrapper {
      clear: both; }
      .my-addresses-wrapper .my-addresses-inner .actions-wrapper .save-btn {
        background: #ff6501;
        border: 1px solid #ff6501;
        width: 100%;
        margin-bottom: 20px; }
        @media (min-width: 760px) {
          .my-addresses-wrapper .my-addresses-inner .actions-wrapper .save-btn {
            width: auto;
            margin-bottom: 0;
            margin-right: 20px; } }
      .my-addresses-wrapper .my-addresses-inner .actions-wrapper input[type="submit"] {
        width: 100%; }
        @media (min-width: 760px) {
          .my-addresses-wrapper .my-addresses-inner .actions-wrapper input[type="submit"] {
            width: auto; } }

.my-mothercare-page-wrapper {
  border: 1px solid #d6d6d6; }
  .my-mothercare-page-wrapper .my-mothercare-section-header {
    margin: 0;
    color: #888;
    background: #f6f6f6;
    font-size: 20px;
    line-height: 22px;
    padding: 9px 20px;
    border-bottom: 1px solid #d6d6d6; }
    .my-mothercare-page-wrapper .my-mothercare-section-header h3 {
      margin: 0; }
  .my-mothercare-page-wrapper .my-mothercare-section-content {
    padding: 20px; }
    .my-mothercare-page-wrapper .my-mothercare-section-content .card-info,
    .my-mothercare-page-wrapper .my-mothercare-section-content .add-card-form {
      border-top: 1px solid #d6d6d6;
      margin-top: 20px; }
      .my-mothercare-page-wrapper .my-mothercare-section-content .card-info h3,
      .my-mothercare-page-wrapper .my-mothercare-section-content .add-card-form h3 {
        margin: 20px 0 10px;
        color: #003971; }
    .my-mothercare-page-wrapper .my-mothercare-section-content .card-wrapper {
      border: 1px solid #d6d6d6;
      padding: 20px 25px 20px 20px;
      border-radius: 4px;
      display: inline-block;
      position: relative; }
      .my-mothercare-page-wrapper .my-mothercare-section-content .card-wrapper:after {
        background-image: url("../logo_gr.png");
        content: '';
        position: absolute;
        opacity: 0.6;
        top: 10px;
        right: 10px;
        display: block;
        width: 35px;
        height: 100px;
        z-index: -1;
        background-size: contain;
        background-repeat: no-repeat; }
      .my-mothercare-page-wrapper .my-mothercare-section-content .card-wrapper .card-full-name {
        font-size: 14px; }
      .my-mothercare-page-wrapper .my-mothercare-section-content .card-wrapper .card-number {
        color: #003971;
        font-weight: 400;
        font-size: 18px;
        margin-top: 5px; }

.user-pass .information-text {
  margin: 5px auto 20px;
  max-width: none; }

.page-user.not-logged-in .l-content form {
  max-width: 570px;
  margin: 20px auto;
  border: 1px solid #d6d6d6;
  padding: 22px 24px;
  position: relative; }
  @media (min-width: 760px) {
    .page-user.not-logged-in .l-content form {
      margin: 40px auto; } }

.page-user .l-content .user-forms-wrapper .user-login-left {
  padding: 0;
  width: 100%; }
  @media (min-width: 760px) {
    .page-user .l-content .user-forms-wrapper .user-login-left {
      width: 50%;
      padding: 0 10px;
      float: left; } }

.page-user .l-content .user-forms-wrapper .user-register-right {
  padding: 0;
  width: 100%; }
  @media (min-width: 760px) {
    .page-user .l-content .user-forms-wrapper .user-register-right {
      width: 50%;
      padding: 0 10px;
      float: right; } }

.page-user .l-content .user-forms-wrapper .user-register-right-wrapper {
  margin: 20px auto; }
  @media (min-width: 760px) {
    .page-user .l-content .user-forms-wrapper .user-register-right-wrapper {
      margin: 40px auto 20px; } }

form.user-login .b-signin-text {
  right: auto;
  text-align: left;
  max-width: 250px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  position: relative;
  left: 0; }
  @media (min-width: 760px) {
    form.user-login .b-signin-text {
      position: absolute;
      color: #fff;
      top: 50%;
      left: 30px;
      transform: translateY(-50%); } }

.user-register-with-card .b-register-text,
.user-register-form .register-banner-container .b-register-text {
  text-align: left;
  max-width: 270px;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  position: relative;
  left: 0; }
  @media (min-width: 760px) {
    .user-register-with-card .b-register-text,
    .user-register-form .register-banner-container .b-register-text {
      position: absolute;
      top: 50%;
      left: 30px;
      transform: translateY(-50%);
      color: #fff; } }

.password-strength {
  width: 100%; }
  @media (min-width: 760px) {
    .password-strength {
      float: right;
      margin-top: 1.2em;
      width: 17em; } }

.user-register-form .selector {
  width: 100% !important; }

.user-register-form .form-actions input[type="submit"],
.user-login .form-actions input[type="submit"],
.user-pass .form-actions input[type="submit"] {
  padding: 0;
  width: 100%; }
  @media (min-width: 760px) {
    .user-register-form .form-actions input[type="submit"],
    .user-login .form-actions input[type="submit"],
    .user-pass .form-actions input[type="submit"] {
      padding: 0 40px;
      width: auto; } }

.content-wrapper.with-sidebar .main-content-region {
  display: inline-block;
  float: left;
  width: 100%;
  padding: 0; }
  @media (min-width: 760px) {
    .content-wrapper.with-sidebar .main-content-region {
      padding-left: 10px;
      width: 75%; } }

.my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper {
  float: left;
  width: 100%;
  margin-top: 10px; }
  @media (min-width: 760px) {
    .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper {
      margin-top: 20px;
      width: 25%; } }
  @media (min-width: 760px) and (min-width: 1000px) {
    .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper {
      width: 25%;
      margin-top: 0; } }
  .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper:last-child {
    width: 100%;
    float: left; }
    @media (min-width: 760px) {
      .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper:last-child {
        width: 100%; } }
  @media (min-width: 760px) and (min-width: 1000px) {
    .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper:last-child {
      width: 25%;
      margin-top: 15px; } }

.my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total .order-row-link,
.my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations .order-row-link {
  padding: 12px 3px; }

.my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total,
.my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations {
  text-align: left; }
  @media (min-width: 760px) {
    .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total,
    .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations {
      text-align: right; } }
  @media (min-width: 760px) and (min-width: 1000px) {
    .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-total,
    .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations {
      text-align: left; } }

.my-orders-page-wrapper .order-wrapper .order-full-wrapper .right-side {
  width: 100%;
  margin-top: 20px; }
  @media (min-width: 760px) {
    .my-orders-page-wrapper .order-wrapper .order-full-wrapper .right-side {
      margin-top: 0;
      padding-left: 15px;
      width: 35%; } }

.my-orders-page-wrapper .order-wrapper .order-full-wrapper {
  padding: 30px 0; }
  @media (min-width: 760px) {
    .my-orders-page-wrapper .order-wrapper .order-full-wrapper {
      padding: 30px; } }

.my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations .order-row-link {
  margin-top: 20px;
  float: left;
  max-width: 200px;
  width: 100%;
  text-align: center; }
  @media (min-width: 760px) {
    .my-orders-page-wrapper .order-wrapper .order-row-wrapper .order-row-item-wrapper .order-operations .order-row-link {
      margin-top: 0; } }

.policies-accept-text {
  margin-bottom: 20px; }

.my-account-edit {
  max-width: 570px; }
  .my-account-edit .field-name-field-mobile-prefix {
    width: 30%;
    float: left;
    padding-right: 20px; }
  .my-account-edit .field-name-field-phone {
    width: 70%;
    float: left; }
  .my-account-edit .field-name-field-gdpr-profiling-sw {
    margin-top: 10px; }
  .my-account-edit .field-name-field-block-of-flats {
    clear: both; }
  .my-account-edit .field-name-field-gdpr-profiling-sw,
  .my-account-edit .group-contact-methods {
    clear: both; }
  .my-account-edit .group-contact-methods {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .my-account-edit .group-contact-methods h3 {
      display: block;
      width: 100%; }
    .my-account-edit .group-contact-methods .accept-all-methods-check {
      position: relative;
      padding-left: 20px;
      margin-bottom: 20px;
      font-size: 15px;
      line-height: 15px;
      font-family: "futura-pt";
      font-weight: 300;
      cursor: pointer;
      -webkit-font-smoothing: subpixel-antialiased; }
      .my-account-edit .group-contact-methods .accept-all-methods-check:before {
        width: 12px;
        height: 12px;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        display: block;
        content: "";
        border: 1px solid #414141;
        position: absolute;
        top: 2px;
        border-radius: 2px;
        left: 0; }
      .my-account-edit .group-contact-methods .accept-all-methods-check.checked:after {
        width: 8px;
        height: 8px;
        border-radius: 2px;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        display: block;
        content: "";
        background: #003971;
        position: absolute;
        top: 4px;
        left: 2px;
        border: 1px solid #414141; }
    .my-account-edit .group-contact-methods .field-type-list-boolean {
      width: 100%;
      flex-basis: 100%; }
      @media (min-width: 760px) {
        .my-account-edit .group-contact-methods .field-type-list-boolean {
          width: 50%;
          flex-basis: 50%; } }
      @media (min-width: 1000px) {
        .my-account-edit .group-contact-methods .field-type-list-boolean {
          width: 25%;
          flex-basis: 25%; } }
